import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  HamburgerMenuIcon,
  DotFilledIcon,
  CheckIcon,
  ChevronRightIcon,
  DotsVerticalIcon,
  EyeOpenIcon,
  Pencil1Icon,
  Pencil2Icon,
  LockOpen1Icon,
  TrashIcon,
} from "@radix-ui/react-icons";
import EyeIcon from "../icons/EyeIcon";

const DropDownDots = (props) => {
  const [bookmarksChecked, setBookmarksChecked] = React.useState(true);
  const [urlsChecked, setUrlsChecked] = React.useState(false);
  const [person, setPerson] = React.useState("pedro");

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className="rounded-md w-[35px] h-[35px] inline-flex items-center justify-center text-violet11  outline-none hover:bg-black hover:bg-opacity-5 focus:shadow-[0_0_0_2px] focus:shadow-neutral-200"
          aria-label="Customise options"
        >
          <DotsVerticalIcon />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          className="min-w-[220px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
        >
          <DropdownMenu.Item
            onSelect={(e) => {
              props.openForm(e, props.info.row.original, true);
            }}
            className="group text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[10px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-blue-500 data-[highlighted]:text-white"
          >
            <EyeOpenIcon className="mr-2" /> Afficher
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={(e) => {
              props.openForm(e, props.info.row.original, false);
            }}
            className="group text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[10px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-blue-500 data-[highlighted]:text-white"
          >
            <Pencil2Icon className="mr-2" /> Éditer
          </DropdownMenu.Item>

          <DropdownMenu.Separator className="h-[1px] bg-neutral-200 m-[5px]" />
          {/* <DropdownMenu.Item
            onSelect={() =>
              props.openAccountActivationForm(props.info.row.original)
            }
            className="group text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[10px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-blue-500 data-[highlighted]:text-white"
          >
            <LockOpen1Icon className="mr-2" /> Activer le compte
          </DropdownMenu.Item> */}

          {/* <DropdownMenu.Separator className="h-[1px] bg-neutral-200 m-[5px]" /> */}
          <DropdownMenu.Item
            onSelect={() => props.openDeleteForm(props.info.row.original)}
            className="group text-[13px] leading-none text-red-500 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[10px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-red-500 data-[highlighted]:text-white"
          >
            <TrashIcon className="mr-2" />
            Supprimer la souscription
          </DropdownMenu.Item>
          <DropdownMenu.Arrow className="fill-white" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DropDownDots;

import React, { useEffect, useState } from "react";
import FilterSelect from "./FilterSelect";
import FilterValue from "./FilterValue";
import { HiPlus, HiMinus } from "react-icons/hi";
import {
  filterColumns,
  filtersOperators,
  getCountOperators,
  getValuesCount,
} from "../../../utils/dataSets/filtersData";
const FilterLine = ({
  onChange,
  id,
  columnValue,
  operatorValue,
  dataValue,
  addParam,
  removeParam,
  filter,
}) => {
  const [column, setColumn] = useState(columnValue ? columnValue : "");
  const [operator, setOperator] = useState(operatorValue ? operatorValue : "");
  const [value, setValue] = useState(dataValue ? dataValue : "");
  const [isOperatorChosen, setIsOperatorChosen] = useState(false);

  const columns = filterColumns;

  const operators = [
    {
      id: 1,
      value: "$eq",
      label: "égal",
      valuesCount: 1,
      isStringOnly: false,
    },
    {
      id: 2,
      value: "$eqc",
      label: "égal (case-sensitive)",
      valuesCount: 1,
      isStringOnly: false,
    },
    {
      id: 3,
      value: "$ne",
      label: "est différent de",
      valuesCount: 1,
      isStringOnly: false,
    },
    {
      id: 4,
      value: "$contains",
      label: "contient",
      valuesCount: 1,
      isStringOnly: false,
    },
    {
      id: 5,
      value: "$containsc",
      label: "contient (case-sensitive)",
      valuesCount: 1,
      isStringOnly: true,
    },
    {
      id: 6,
      value: "$notContains",
      label: "ne contient pas",
      valuesCount: 1,
      isStringOnly: true,
    },
    {
      id: 7,
      value: "$notContainsc",
      label: "ne contient pas (case-sensitive)",
      valuesCount: 1,
      isStringOnly: true,
    },
    {
      id: 8,
      value: "$gt",
      label: "plus grand que",
      isStringOnly: false,
    },
    {
      id: 9,
      value: "$gte",
      label: "plus grand ou égal à",
      isStringOnly: false,
    },
    {
      id: 10,
      value: "$lt",
      label: "plus petit que",
      isStringOnly: false,
    },
    {
      id: 11,
      value: "$lte",
      label: "plus petit ou égal à",
      isStringOnly: false,
    },

    {
      id: 12,
      value: "$null",
      label: "est null",
      valuesCount: 0,
      isStringOnly: false,
    },
    {
      id: 13,
      value: "$notNull",
      label: "n'est pas null",
      valuesCount: 0,
      isStringOnly: false,
    },
    {
      id: 14,
      value: "$nullorempty",
      label: "est vide (null ou string vide)",
      valuesCount: 0,
      isStringOnly: false,
    },
    {
      id: 15,
      value: "$startsWith",
      label: "commence par",
      valuesCount: 1,
      isStringOnly: true,
    },
    {
      id: 16,
      value: "$startsWithc",
      label: "commence par (case-sensitive)",
      valuesCount: 1,
      isStringOnly: true,
    },
    {
      id: 17,
      value: "$endsWith",
      label: "finit par",
      valuesCount: 1,
      isStringOnly: true,
    },
    {
      id: 18,
      value: "$endsWithc",
      label: "finit par (case-sensitive)",
      valuesCount: 1,
      isStringOnly: true,
    },
  ];

  const onLineChange = () => {
    onChange(id, column, operator, value);
  };
  useEffect(() => {
    onChange(id, column, operator, value);
  }, [column, operator, value]);

  const getValuesCount = (value) => {
    const operator = filtersOperators.find(
      (operator) => operator.value === value
    );
    return operator ? operator.valuesCount : null; // Return null if not found
  };

  const getIsStringOnly = (operatorValue) => {
    const operator = operators.find((op) => op.value === operatorValue);
    console.log("isStringOnly", operator.isStringOnly);

    return operator ? operator.isStringOnly : null; // Returns null if no operator is found
  };

  const getColumnType = (value) => {
    const column = filterColumns.find((column) => column.value === value);
    return column ? column.type : null; // Return null if not found
  };

  return (
    <div className="flex gap-x-1 px-5 py-1">
      <div className="w-full">
        <FilterSelect
          items={columns}
          value={columnValue}
          type="column"
          onChange={(e) => {
            if (e !== null && e !== "") {
              setColumn(e);
              console.log(getColumnType(e));
            }
          }}
        />
      </div>
      <div className="w-full">
        <FilterSelect
          items={operators}
          value={operatorValue}
          type="operator"
          onChange={(e) => {
            console.log(getValuesCount(e));

            if (e !== null && e !== "") {
              setOperator(e);
            }
          }}
        />
      </div>
      {operator !== "" && getValuesCount(operator) !== 0 && (
        <div className="w-full">
          {/* <input
            type="text"
            defaultValue={dataValue}
            placeholder="Entrez une valeur"
            onChange={(e) => {
              if (e.target.value !== "") {
                setValue(e.target.value);
              }
            }}
            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
          /> */}

          <FilterValue
            columnType={getColumnType(column)}
            defaultValue={dataValue}
            isStringOnly={getIsStringOnly(operator)}
            valuesCount={getValuesCount(operator)}
            setValue={setValue}
            onChange={(e) => {
              if (e.target.value !== "") {
                setValue(e.target.value);
              }
            }}
          />
        </div>
      )}
      <div className="flex w-20">
        <button
          onClick={() => removeParam()}
          disabled={id === 0}
          className={` inline-flex max-h-[40px] items-center py-2.5 px-2.5 font-medium text-gray-900 focus:outline-none  bg-white  rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 transition-all disabled:opacity-20 disabled:cursor-not-allowed`}
        >
          <HiMinus size={18}></HiMinus>
        </button>
        <button
          onClick={() => addParam(id)}
          className={` inline-flex max-h-[40px] items-center py-2.5 px-2.5 font-medium text-gray-900 focus:outline-none  bg-white  rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 transition-all`}
        >
          <HiPlus size={18}></HiPlus>
        </button>
      </div>
    </div>
  );
};

export default FilterLine;

import React from "react";
import { useAuth } from "react-oidc-context";

const LoginPage = () => {
  const auth = useAuth();
  return (
    <div className="flex justify-center items-center h-screen max-h-screen w-screen min-w-screen bg-selago-50 dark:bg-slate-800 text-gray-900 dark:text-white">
      <div className="text-center max-w-lg w-full bg-white dark:bg-gray-900 p-4 rounded-lg shadow-lg ">
        <h1 className="text-2xl font-bold mb-10">Connexion requise</h1>
        <button
          type="button"
          onClick={() => {
            auth.clearStaleState();

            auth.signinRedirect();
          }}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-8"
        >
          Se connecter
        </button>
        <div>
          <a
            href="https://www.byvets.be"
            className="text-xs text-gray-600 underline"
          >
            Revenir sur byvets.be
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import { DatePickerInput } from "@mantine/dates";
import useDarkSide from "../../hooks/useDarkSide";
import useTheme from "../../hooks/useTheme";
import Datepicker from "react-tailwindcss-datepicker";
import { useState } from "react";

function TableFilter({ column, table }) {
  const [dTheme, dThemeTogggle] = useTheme();

  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    column.setFilterValue((old) => [newValue.startDate, newValue.endDate]);
    setValue(newValue);
  };

  const dateColumns = [
    "shareholding_started_at",
    "shareholding_ended_at",
    "subscription_started_at",
    "subscription_ended_at",
    "last_pegase_updated_at",
    "formation_base_participation_date",
    "formation_base_invitation_last_date",
    "formation_perf_invitation_date",
    "formation_perf_participation_date",
    "formation_perf_invitation_last_date",
  ];

  if (dateColumns.includes(column.id)) {
    return (
      <div className="flex space-x-2">
        <Datepicker
          i18n="fr"
          value={value}
          onChange={handleValueChange}
          placeholder="Entrez dates..."
          showShortcuts
          startFrom={new Date()}
          minDate={new Date("1999-01-01")}
          maxDate={new Date().setFullYear(2050)}
          configs={{
            shortcuts: {
              today: "Aujourd'hui",
              yesterday: "Hier",
              past: (period) => `J-${period} jours`,
              currentMonth: "Ce mois-ci",
              pastMonth: "Le mois dernier",
            },
          }}
          inputClassName={
            "p-2 font-lg shadow  border-block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          }
        />
        {/* <DatePickerInput
          type="range"
          // label="Pick dates range"
          placeholder="Pick dates range"
          // value={[columnFilterValue?.[0] ?? "", columnFilterValue?.[1] ?? ""]}
          onChange={(e) => {
            if (e[0] !== null) {
              column.setFilterValue((old) => [e[0], null]);
            }
            if (e[1] !== null) {
              column.setFilterValue((old) => [e[0], e[1]]);
            }
          }}
          classNames={{ input: "bg-red-500" }}
        /> */}
        {/* <input
          type="date"
          value={columnFilterValue?.[0] ?? ""}
          onChange={(e) =>
            column.setFilterValue((old) => [e.target.value, old?.[1]])
          }
          placeholder={`Min`}
          className="p-2 font-lg shadow  border-block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <input
          type="date"
          value={columnFilterValue?.[1] ?? ""}
          onChange={(e) =>
            column.setFilterValue((old) => [old?.[0], e.target.value])
          }
          placeholder={`Max`}
          className="p-2 font-lg shadow  border-block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        /> */}
      </div>
    );
  }

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={columnFilterValue?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={columnFilterValue?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <>
      <input
        type="text"
        value={columnFilterValue ?? ""}
        onChange={(e) => column.setFilterValue(e.target.value)}
        placeholder={`Rechercher...`}
        className="p-2 font-normal shadow  border-block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
    </>
  );
}

export default TableFilter;

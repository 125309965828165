import { useFormik } from "formik"
import React from "react"
import * as Select from "@radix-ui/react-select"
import classNames from "classnames"
import CheckIcon from "../../icons/CheckIcon"
import ChevronDownIcon from "../../icons/ChevronDownIcon"
import ChevronUpIcon from "../../icons/ChevronUpIcon"
import { formatDate } from "../../../utils/formatDate"
import ScrollAreaCustom from "../../scrollarea"
import CheckboxCustom from "./CheckboxCustom"
import FormSelect from "./FormSelect"
import * as Yup from "yup"
import InfoCircleIcon from "../../icons/InfoCircle"

const AddSubscriptionForm = ({ isFormLocked = false, close, mutation }) => {
  const validationSchema = Yup.object().shape({
    lastname: Yup.string().required("Requis"),
    firstname: Yup.string().required("Requis"),
    address: Yup.string().required("Requis"),
    country: Yup.string().required("Requis"),
    city: Yup.string().required("Requis"),
    zipcode: Yup.string().required("Requis"),
    phone: Yup.string().required("Requis"),
    email: Yup.string().required("Requis"),
    server_users: Yup.number().required("Requis"),
    price: Yup.number().required("Requis"),
  })

  const formik = useFormik({
    initialValues: {
      lastname: "",
      firstname: "",
      omv: "",
      deposit: "",
      address: "",
      zipcode: "",
      city: "",
      country: "",
      email: "",
      phone: "",
      company: "",
      vat: "",
      is_vat_validated: false,
      shareholding_type: "",
      shareholding_started_at: "",
      shareholding_ended_at: "",
      subscription_started_at: "",
      subscription_ended_at: "",
      server_users: 0,
      migrate_from: "none",
      payment_status: "pending",
      leaving_reason: "",
      operating_system: "",
      last_pegase_version: "",
      last_pegase_updated_at: "",
      formation_base_invitation_date: "",
      formation_base_participation_date: "",
      formation_base_invitation_last_date: "",
      formation_perf_invitation_date: "",
      formation_perf_participation_date: "",
      formation_perf_invitation_last_date: "",
      ref_pegase: "",
      title: "",
      politesse: "",
      price: 0,
    },
    validationSchema,

    onSubmit: values => {
      mutation.mutate(values)
    },
  })

  const selectShareholdingTypeOptions = [
    { value: "", label: "Aucun" },
    { value: "a", label: "A" },
    { value: "b", label: "B" },
    { value: "c", label: "C" },
  ]

  const selectPaymentStatusOptions = [
    { value: null, label: "Aucun" },
    { value: "cancelled", label: "Annulé" },
    { value: "expired", label: "Expiré" },
    { value: "paid", label: "Payé" },
  ]
  const selectMigrateFromOptions = [
    { value: "none", label: "Aucun" },
    { value: "fuga", label: "Fuga" },
    { value: "vetesys", label: "Vetesys" },
    { value: "cinnabear", label: "Cinnabear" },
  ]
  const selectOSOptions = [
    { value: null, label: "-" },
    { value: "mac", label: "Mac" },
    { value: "windows", label: "Windows" },
  ]

  return (
    <form onSubmit={formik.handleSubmit}>
      <ScrollAreaCustom>
        <div id="info-customer">
          <h2 className="text-lg font-semibold mb-2">
            Informations sur le client
          </h2>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="title"
                className="block mb-2 text-xs font-normal text-gray-400 "
              >
                Titre
              </label>

              <input
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="title"
                name="title"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.title}
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="politesse"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Politesse
              </label>

              <input
                id="politesse"
                name="politesse"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.politesse}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="lastname"
                className="block mb-2 text-xs font-normal text-gray-400 "
              >
                Nom
              </label>

              <input
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="lastname"
                name="lastname"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.lastname}
                onBlur={formik.handleBlur}
              />
              {formik.errors.lastname && formik.touched.lastname ? (
                <div className="error-message flex ml-0.5 mt-0.5">
                  <InfoCircleIcon />
                  <span className="ml-0.5">{formik.errors.lastname}</span>
                </div>
              ) : null}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="firstname"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Prénom
              </label>

              <input
                id="firstname"
                name="firstname"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.firstname}
                onBlur={formik.handleBlur}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formik.errors.firstname && formik.touched.firstname ? (
                <div className="error-message flex ml-0.5 mt-0.5">
                  <InfoCircleIcon />
                  <span className="ml-0.5">{formik.errors.firstname}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="omv"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                OMV
              </label>

              <input
                id="omv"
                name="omv"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.omv}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="deposit"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Numéro de dépôt
              </label>

              <input
                type="text"
                id="deposit"
                name="deposit"
                onChange={formik.handleChange}
                value={formik.values.deposit}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="address"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Adresse*
              </label>

              <input
                type="text"
                id="address"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                onBlur={formik.handleBlur}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formik.errors.address && formik.touched.address ? (
                <div className="error-message flex ml-0.5 mt-0.5">
                  <InfoCircleIcon />
                  <span className="ml-0.5">{formik.errors.address}</span>
                </div>
              ) : null}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="zipcode"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Code postal
              </label>

              <input
                type="text"
                id="zipcode"
                name="zipcode"
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={formik.handleChange}
                value={formik.values.zipcode}
                onBlur={formik.handleBlur}
              />
              {formik.errors.zipcode && formik.touched.zipcode ? (
                <div className="error-message flex ml-0.5 mt-0.5">
                  <InfoCircleIcon />
                  <span className="ml-0.5">{formik.errors.zipcode}</span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="city"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Ville
              </label>

              <input
                type="text"
                id="city"
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                onBlur={formik.handleBlur}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formik.errors.city && formik.touched.city ? (
                <div className="error-message flex ml-0.5 mt-0.5">
                  <InfoCircleIcon />
                  <span className="ml-0.5">{formik.errors.city}</span>
                </div>
              ) : null}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="country"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Pays
              </label>

              <input
                type="text"
                id="country"
                name="country"
                onChange={formik.handleChange}
                value={formik.values.country}
                onBlur={formik.handleBlur}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formik.errors.country && formik.touched.country ? (
                <div className="error-message flex ml-0.5 mt-0.5">
                  <InfoCircleIcon />
                  <span className="ml-0.5">{formik.errors.country}</span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="email"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Email
              </label>

              <input
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formik.errors.email && formik.touched.email ? (
                <div className="error-message flex ml-0.5 mt-0.5">
                  <InfoCircleIcon />
                  <span className="ml-0.5">{formik.errors.email}</span>
                </div>
              ) : null}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="phone"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Téléphone
              </label>

              <input
                type="text"
                id="phone"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                onBlur={formik.handleBlur}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formik.errors.phone && formik.touched.phone ? (
                <div className="error-message flex ml-0.5 mt-0.5">
                  <InfoCircleIcon />
                  <span className="ml-0.5">{formik.errors.phone}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="shareholding_type"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Actionnariat
              </label>

              <FormSelect
                name="shareholding_type"
                items={selectShareholdingTypeOptions}
                formik={formik}
                value={formik.values.shareholding_type}
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="shareholding_started_at"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Depuis le
              </label>

              <input
                type="date"
                id="shareholding_started_at"
                name="shareholding_started_at"
                defaultValue={
                  formatDate(formik.values.shareholding_started_at) || null
                }
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="shareholding_ended_at"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Jusqu'au
              </label>

              <input
                type="date"
                id="shareholding_ended_at"
                name="shareholding_ended_at"
                defaultValue={
                  formatDate(formik.values.shareholding_ended_at) || null
                }
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
        </div>
        <div id="info-company">
          <h2 className="text-lg font-semibold mt-4 mb-2">
            Informations sur la société
          </h2>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="company"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Société
              </label>

              <input
                type="text"
                id="company"
                name="company"
                value={formik.values.company}
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="vat"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                TVA
              </label>
              <input
                type="text"
                id="vat"
                name="vat"
                value={formik.values.vat}
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="is_vat_valid"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                TVA validée ?
              </label>

              <div className="flex h-[42px] items-center">
                <CheckboxCustom
                  name="is_vat_validated"
                  value={formik.values.is_vat_validated}
                  formik={formik}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="info-subscription">
          <h2 className="text-lg font-semibold mt-4 mb-2">
            Informations sur la souscription
          </h2>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="subscription_started_at"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Depuis le
              </label>

              <input
                type="date"
                id="subscription_started_at"
                name="subscription_started_at"
                defaultValue={
                  formatDate(formik.values.subscription_started_at) || null
                }
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="subscription_ended_at"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Jusqu'au
              </label>
              <input
                type="date"
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="subscription_ended_at"
                name="subscription_ended_at"
                defaultValue={
                  formatDate(formik.values.subscription_ended_at) || null
                }
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="leaving_reason"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Motif de départ
              </label>

              <textarea
                id="leaving_reason"
                name="leaving_reason"
                defaultValue={formik.values.leaving_reason}
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="server_users"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Utilisateurs serveur
              </label>

              <input
                type="number"
                id="server_users"
                defaultValue={formik.values.server_users}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                step={5}
                max={10}
                min={0}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formik.errors.server_users && formik.touched.server_users ? (
                <div className="error-message flex ml-0.5 mt-0.5">
                  <InfoCircleIcon />
                  <span className="ml-0.5">{formik.errors.server_users}</span>
                </div>
              ) : null}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="migrate_from"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Migration
              </label>

              <FormSelect
                name="migrate_from"
                items={selectMigrateFromOptions}
                formik={formik}
                value={formik.values.migrate_from}
              ></FormSelect>
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="payment_status"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Statut du paiement
              </label>

              <FormSelect
                name="payment_status"
                items={selectPaymentStatusOptions}
                formik={formik}
                value={formik.payment_status}
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="price"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Prix
              </label>

              <input
                type="number"
                id="price"
                name="price"
                value={formik.values.price}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formik.errors.price && formik.touched.price ? (
                <div className="error-message flex ml-0.5 mt-0.5">
                  <InfoCircleIcon />
                  <span className="ml-0.5">{formik.errors.price}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div id="info-technical">
          <h2 className="text-lg font-semibold mt-4 mb-2">
            Informations techniques
          </h2>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="operating_system"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Système d'exploitation
              </label>

              <FormSelect
                name="operating_system"
                items={selectOSOptions}
                formik={formik}
                value={formik.values.operating_system}
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="last_pegase_version"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Dernière version de Pégase
              </label>

              <input
                type="text"
                id="last_pegase_version"
                name="last_pegase_version"
                value={formik.values.last_pegase_version}
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="last_pegase_updated_at"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Dernière mise-à-jour le
              </label>

              <input
                type="date"
                id="last_pegase_updated_at"
                name="last_pegase_updated_at"
                defaultValue={
                  formatDate(formik.values.last_pegase_updated_at) || null
                }
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_base_invitation_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Invitation formation de base
              </label>

              <input
                type="date"
                id="formation_base_invitation_date"
                name="formation_base_invitation_date"
                defaultValue={
                  formatDate(formik.values.formation_base_invitation_date) ||
                  null
                }
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_base_participation_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                participation formation de base
              </label>

              <input
                type="date"
                id="formation_base_participation_date"
                name="formation_base_participation_date"
                defaultValue={
                  formatDate(formik.values.formation_base_participation_date) ||
                  null
                }
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_base_invitation_last_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Dernière invitation formation de base
              </label>

              <input
                type="date"
                id="formation_base_invitation_last_date"
                name="formation_base_invitation_last_date"
                defaultValue={
                  formatDate(
                    formik.values.formation_base_invitation_last_date
                  ) || null
                }
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_perf_invitation_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Invitation formation de perf
              </label>

              <input
                type="date"
                id="formation_perf_invitation_date"
                name="formation_perf_invitation_date"
                defaultValue={
                  formatDate(formik.values.formation_perf_invitation_date) ||
                  null
                }
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_perf_participation_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                participation formation de perf
              </label>

              <input
                type="date"
                id="formation_perf_participation_date"
                name="formation_perf_participation_date"
                defaultValue={
                  formatDate(formik.values.formation_perf_participation_date) ||
                  null
                }
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_perf_invitation_last_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Dernière invitation formation de perf
              </label>

              <input
                type="date"
                id="formation_perf_invitation_last_date"
                name="formation_perf_invitation_last_date"
                defaultValue={
                  formatDate(
                    formik.values.formation_perf_invitation_last_date
                  ) || null
                }
                onChange={formik.handleChange}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="ref_pegase"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Référence Pégase
              </label>

              <input
                type="text"
                id="ref_pegase"
                name="ref_pegase"
                onChange={formik.handleChange}
                value={formik.values.ref_pegase}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
        </div>
      </ScrollAreaCustom>
      <div className="flex justify-end gap-x-2 px-5 py-4">
        <button
          type="button"
          onClick={e => {
            close(e)
          }}
          className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          Fermer
        </button>
        <button
          type="submit"
          disabled={!formik.isValid}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-70"
        >
          Enregistrer
        </button>
      </div>
    </form>
  )
}

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classNames("SelectItem", className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="SelectItemIndicator">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    )
  }
)

export default AddSubscriptionForm

import React from "react";
import AdminLayout from "../components/layout/AdminLayout";
import SelectUsers from "../components/forms/select/SelectUsers";

const HomePage = () => {
  return (
    <AdminLayout>
      <h1 className="text-2xl">Dashboard</h1>
      <h2 className="text-lg">Zone en construction</h2>
      {/* <SelectUsers /> */}
    </AdminLayout>
  );
};

export default HomePage;

import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";

const ToggleAccount = ({
  subscription,
  formik,
  isFormLocked,
  activateAccount,
  desactivateAccount,
  reactivateAccount,
}) => {
  const [showNewAccountPassword, setShowNewAccountPassword] = useState(false);
  const accountFormik = useFormik({
    initialValues: {
      new_account_password: null,
      new_account_password_confirmation: null,
    },
    validationSchema: Yup.object({
      new_account_password: Yup.string()
        .min(10, "Minimum 10 caractères")
        .required("Mot de passe requis"),
      new_account_password_confirmation: Yup.string().oneOf(
        [Yup.ref("new_account_password"), null],
        "Les mots de passe doivent être identiques"
      ),
    }),
    onSubmit: (values) => {
      activateAccount.mutate(values.new_account_password);
    },
  });
  const desactivateAccountFormik = useFormik({
    initialValues: {},

    onSubmit: (values) => {
      desactivateAccount.mutate();
    },
  });
  const reactivateAccountFormik = useFormik({
    initialValues: {},

    onSubmit: (values) => {
      reactivateAccount.mutate();
    },
  });
  if (
    !subscription.is_active &&
    !subscription.username &&
    !subscription.keycloak_id
  ) {
    return (
      <form onSubmit={accountFormik.handleSubmit}>
        {" "}
        <div className="flex gap-x-4">
          <div className="mb-4 flex-1">
            <label
              htmlFor="operating_system"
              className="block mb-2 text-xs font-regular text-gray-400 "
            >
              Mot de passe
            </label>

            <input
              type={showNewAccountPassword ? "text" : "password"}
              className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="new_account_password"
              name="new_account_password"
              onChange={accountFormik.handleChange}
              onBlur={accountFormik.handleBlur}
            />
            {accountFormik.errors.new_account_password &&
            accountFormik.touched.new_account_password ? (
              <div className="text-xs text-red-500 ml-1">
                {accountFormik.errors.new_account_password}
              </div>
            ) : null}

            <p
              onClick={() => setShowNewAccountPassword(!showNewAccountPassword)}
              className="text-xs mt-1 ml-1 hover:underline cursor-pointer"
            >
              {showNewAccountPassword ? "Masquer" : "Afficher"} les mots de
              passe
            </p>
          </div>
          <div className="mb-4 flex-1">
            <label
              htmlFor="operating_system"
              className="block mb-2 text-xs font-regular text-gray-400 "
            >
              Confirmation mot de passe
            </label>

            <input
              type={showNewAccountPassword ? "text" : "password"}
              className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="new_account_password_confirmation"
              name="new_account_password_confirmation"
              onChange={accountFormik.handleChange}
              onBlur={accountFormik.handleBlur}
            />
            {accountFormik.errors.new_account_password_confirmation &&
            accountFormik.touched.new_account_password_confirmation ? (
              <div className="text-xs text-red-500 ml-1">
                {accountFormik.errors.new_account_password_confirmation}
              </div>
            ) : null}
          </div>
          <div className="text-right self-center -mt-3  ">
            {" "}
            <button
              type="button"
              onClick={() => {
                // createAccountMutation.mutate()
                accountFormik.handleSubmit();
              }}
              className="text-white bg-blue-700 h-[42px] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Créer compte
            </button>
          </div>
        </div>
      </form>
    );
  }
  return (
    <div>
      <div className="flex gap-x-4">
        <div className="mb-4 flex-1">
          <label
            htmlFor="title"
            className="block mb-2 text-xs font-normal text-gray-400 "
          >
            Nom d'utilisateur
          </label>
          {isFormLocked ? (
            <div className="h-[42px] flex items-center text-sm">
              {subscription.username}
            </div>
          ) : (
            <input
              className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="username"
              name="username"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.username}
            />
          )}
        </div>
      </div>
      <div className="flex gap-x-4">
        <div className="mb-4 flex-1">
          <label
            htmlFor="title"
            className="block mb-2 text-xs font-normal text-gray-400 "
          >
            Keycloak ID
          </label>
          {isFormLocked ? (
            <div className="h-[42px] flex items-center text-sm">
              {subscription.keycloak_id}
            </div>
          ) : (
            <input
              className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="keycloak_id"
              name="keycloak_id"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.keycloak_id}
            />
          )}
        </div>
        <div className="mb-4 flex-1">
          <label
            htmlFor="title"
            className="block mb-2 text-xs font-normal text-gray-400 "
          >
            Discourse ID
          </label>
          {isFormLocked ? (
            <div className="h-[42px] flex items-center text-sm">
              {subscription.discourse_id}
            </div>
          ) : (
            <input
              className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="discourse_id"
              name="discourse_id"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.discourse_id}
            />
          )}
        </div>
      </div>
      {!subscription.is_active ? (
        <button
          type="button"
          onClick={() => reactivateAccount.mutate()}
          className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:opacity-70 mb-8"
        >
          Réactiver le compte
        </button>
      ) : (
        <button
          type="button"
          onClick={() => desactivateAccount.mutate()}
          className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 disabled:opacity-70 mb-8"
        >
          Désactiver le compte
        </button>
      )}
    </div>
  );
};

export default ToggleAccount;

import React, { useEffect, useState } from "react";
import FormSelect from "./FormSelect";
import FilterSelect from "./FilterSelect";
import FilterLine from "./FilterLine";
import * as RadioGroup from "@radix-ui/react-radio-group";
import * as Select from "@radix-ui/react-select";
import ChevronDownIcon from "../../icons/ChevronDownIcon";
import ChevronUpIcon from "../../icons/ChevronUpIcon";
import CheckIcon from "../../icons/CheckIcon";
import classNames from "classnames";

const FilterForm = ({
  onChange,
  filters,
  addParam,
  removeParam,
  applyFunction,
  cancelFunction,
  setGlobalOperator,
  globalOperator,
  resetFilters,
}) => {
  const [myFilters, setMyFilters] = useState([]);
  const [isReset, setIsReset] = useState(false);
  useEffect(() => {
    setMyFilters(filters);
  }, [filters]);

  return (
    <div>
      <div className="px-5 py-2 text-xl flex">
        <div>
          <h2>Filtrer</h2>
        </div>
        <div>
          <span className=" text-xs ml-2 px-1.5 border rounded-full border-1 border-green-500 text-green-500">
            beta
          </span>
        </div>
      </div>
      <div className="px-5 py-2 text-base flex items-center">
        <p>Rechercher les fiches répondant à</p>
        <Select.Root
          id="global-operator"
          name="global-operator"
          onValueChange={(e) => {
            setGlobalOperator(e);
          }}
          defaultValue={globalOperator}
        >
          <Select.Trigger
            className=" inline-flex justify-between  items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-2"
            aria-label="global operator"
          >
            <Select.Value placeholder={"Choisir une opération"} />
            <Select.Icon className="ml-2 ">
              <ChevronDownIcon />
            </Select.Icon>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content className="overflow-hidden bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-6 shadow-xl">
              <Select.ScrollUpButton className="SelectScrollButton">
                <ChevronUpIcon />
              </Select.ScrollUpButton>
              <Select.Viewport className="SelectViewport">
                <SelectItem
                  key="and"
                  className="hover:bg-blue-700 hover:text-white"
                  value="and"
                >
                  tous les critères
                </SelectItem>
                <SelectItem
                  key="or"
                  className="hover:bg-blue-700 hover:text-white"
                  value="or"
                >
                  au moins un critère
                </SelectItem>
              </Select.Viewport>
              <Select.ScrollDownButton className="SelectScrollButton">
                <ChevronDownIcon />
              </Select.ScrollDownButton>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </div>
      {!isReset &&
        myFilters.map((filter, index) => (
          <>
            <FilterLine
              key={index}
              onChange={onChange}
              id={index}
              filter={filter}
              columnValue={filter.column}
              operatorValue={filter.operator}
              dataValue={filter.value}
              addParam={addParam}
              removeParam={removeParam}
            />
          </>
        ))}

      <div className="flex justify-between gap-x-2 px-5 pt-6">
        <div className="flex">
          <button
            className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 disabled:opacity-70 mb-8"
            onClick={() => {
              resetFilters();
            }}
          >
            Supprimer les filtres actifs
          </button>
        </div>
        <div className=" gap-x-2">
          <button
            onClick={() => cancelFunction()}
            className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Annuler
          </button>
          <button
            onClick={() => applyFunction()}
            className="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classNames("SelectItem", className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="SelectItemIndicator">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default FilterForm;

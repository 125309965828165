import React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { Controller } from "react-hook-form"
import CheckIcon from "../../icons/CheckIcon"

const CheckboxCustom = ({ name, formik, value }) => {
  return (
    <CheckboxPrimitive.Root
      id={name}
      name={name}
      value={value}
      defaultChecked={value}
      onCheckedChange={e => {
        formik.setFieldValue(name, e)
      }}
      className="h-5 w-5 bg-gray-50 border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 flex justify-center items-center rounded"
    >
      <CheckboxPrimitive.Indicator className="text-black dark:text-white">
        <CheckIcon />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
}

export default CheckboxCustom

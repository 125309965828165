import { useFormik } from "formik";
import React, { useState } from "react";
import * as Select from "@radix-ui/react-select";
import classNames from "classnames";
import CheckIcon from "../../icons/CheckIcon";
import ChevronDownIcon from "../../icons/ChevronDownIcon";
import ChevronUpIcon from "../../icons/ChevronUpIcon";
import { formatDate } from "../../../utils/formatDate";
import ScrollAreaCustom from "../../scrollarea";
import CheckboxCustom from "./CheckboxCustom";
import FormSelect from "./FormSelect";
import DatePicker from "react-date-picker";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import ToggleAccount from "./toggleAccount";
import SelectUsers from "../select/SelectUsers";

const EditSubscriptionForm = ({
  selectedSubscription,
  isFormLocked,
  close,
  edit,
  activateAccount,
  desactivateAccount,
  reactivateAccount,
}) => {
  const [showNewAccountPassword, setShowNewAccountPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      lastname: selectedSubscription.lastname,
      firstname: selectedSubscription.firstname,
      omv: selectedSubscription.omv,
      deposit: selectedSubscription.deposit,
      address: selectedSubscription.address,
      zipcode: selectedSubscription.zipcode,
      city: selectedSubscription.city,
      country: selectedSubscription.country,
      email: selectedSubscription.email,
      phone: selectedSubscription.phone,
      company: selectedSubscription.company,
      vat: selectedSubscription.vat,
      is_vat_validated: selectedSubscription.is_vat_validated,
      keycloak_id: selectedSubscription.keycloak_id,
      discourse_id: selectedSubscription.discourse_id,
      username: selectedSubscription.username,
      shareholding_type: selectedSubscription.shareholding_type,
      shareholding_started_at: selectedSubscription.shareholding_started_at,
      shareholding_ended_at: selectedSubscription.shareholding_ended_at,
      subscription_started_at: selectedSubscription.subscription_started_at,
      subscription_ended_at: selectedSubscription.subscription_ended_at,
      server_users: selectedSubscription.server_users,
      migrate_from: selectedSubscription.migrate_from,
      payment_status: selectedSubscription.payment_status,
      leaving_reason: selectedSubscription.leaving_reason,
      operating_system: selectedSubscription.operating_system,
      last_pegase_version: selectedSubscription.last_pegase_version,
      last_pegase_updated_at: selectedSubscription.last_pegase_updated_at,
      formation_base_invitation_date:
        selectedSubscription.formation_base_invitation_date,
      formation_base_participation_date:
        selectedSubscription.formation_base_participation_date,
      formation_base_invitation_last_date:
        selectedSubscription.formation_base_invitation_last_date,
      formation_perf_invitation_date:
        selectedSubscription.formation_perf_invitation_date,
      formation_perf_participation_date:
        selectedSubscription.formation_perf_participation_date,
      formation_perf_invitation_last_date:
        selectedSubscription.formation_perf_invitation_last_date,
      ref_pegase: selectedSubscription.ref_pegase,
      title: selectedSubscription.title,
      politesse: selectedSubscription.politesse,
      calendar_service: selectedSubscription.calendar_service,
      is_valid: selectedSubscription.is_valid,
    },

    onSubmit: (values) => {
      edit.mutate(values);
    },
  });

  const accountFormik = useFormik({
    initialValues: {
      new_account_password: null,
      new_account_password_confirmation: null,
    },
    validationSchema: Yup.object({
      new_account_password: Yup.string()
        .min(10, "Minimum 10 caractères")
        .required("Mot de passe requis"),
      new_account_password_confirmation: Yup.string().oneOf(
        [Yup.ref("new_account_password"), null],
        "Les mots de passe doivent être identiques"
      ),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const selectShareholdingTypeOptions = [
    { value: "none", label: "Aucun" },
    { value: "a", label: "A" },
    { value: "b", label: "B" },
    { value: "c", label: "C" },
  ];

  const selectPaymentStatusOptions = [
    { value: null, label: "Aucun" },
    { value: "cancelled", label: "Annulé" },
    { value: "expired", label: "Expiré" },
    { value: "paid", label: "Payé" },
  ];
  const selectMigrateFromOptions = [
    { value: "none", label: "Aucun" },
    { value: "fuga", label: "Fuga" },
    { value: "vetesys", label: "Vetesys" },
    { value: "cinnabear", label: "Cinnabear" },
  ];
  const selectOSOptions = [
    { value: null, label: "-" },
    { value: "mac", label: "Mac" },
    { value: "windows", label: "Windows" },
  ];

  const selectCalendarOptions = [
    { value: "none", label: "Aucun" },
    { value: "doctorManager", label: "DoctorManager" },
    { value: "captainVet", label: "CaptainVet" },
  ];

  const createAccount = async () => {
    selectedSubscription.is_active = true;
  };
  return (
    <form onSubmit={formik.handleSubmit} onClick={(e) => e.stopPropagation()}>
      <ScrollAreaCustom>
        <div id="info-customer">
          <h2 className="text-lg font-semibold mb-2">
            Informations sur compte
          </h2>
          <ToggleAccount
            subscription={selectedSubscription}
            isFormLocked={isFormLocked}
            formik={formik}
            activateAccount={activateAccount}
            desactivateAccount={desactivateAccount}
            reactivateAccount={reactivateAccount}
          />
          {/* <h3 className="text-base font-semibold mb-2">Changer d'affilié</h3>
          <SelectUsers formik={formik} /> */}

          <h2 className="text-lg font-semibold mb-2 mt-4">
            Informations sur le client
          </h2>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="title"
                className="block mb-2 text-xs font-normal text-gray-400 "
              >
                Titre
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.title}
                </div>
              ) : (
                <input
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="title"
                  name="title"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="politesse"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Politesse
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.politesse}
                </div>
              ) : (
                <input
                  id="politesse"
                  name="politesse"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.politesse}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="lastname"
                className="block mb-2 text-xs font-normal text-gray-400 "
              >
                Nom
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.lastname}
                </div>
              ) : (
                <input
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="lastname"
                  name="lastname"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.lastname}
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="firstname"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Prénom
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.firstname}
                </div>
              ) : (
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.firstname}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="omv"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                OMV
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.omv}
                </div>
              ) : (
                <input
                  id="omv"
                  name="omv"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.omv}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="deposit"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Numéro de dépôt
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.deposit}
                </div>
              ) : (
                <input
                  type="text"
                  id="deposit"
                  name="deposit"
                  onChange={formik.handleChange}
                  value={formik.values.deposit}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="address"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Adresse
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.address}
                </div>
              ) : (
                <input
                  type="text"
                  id="address"
                  name="address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="zipcode"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Code postal
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.zipcode}
                </div>
              ) : (
                <input
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={formik.handleChange}
                  value={formik.values.zipcode}
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="city"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Ville
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.city}
                </div>
              ) : (
                <input
                  type="text"
                  id="city"
                  name="city"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="country"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Pays
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.country}
                </div>
              ) : (
                <input
                  type="text"
                  id="country"
                  name="country"
                  onChange={formik.handleChange}
                  value={formik.values.country}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="email"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Email
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.email}
                </div>
              ) : (
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="phone"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Téléphone
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.phone}
                </div>
              ) : (
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="shareholding_type"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Actionnariat
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.shareholding_type}
                </div>
              ) : (
                <FormSelect
                  name="shareholding_type"
                  items={selectShareholdingTypeOptions}
                  formik={formik}
                  value={formik.values.shareholding_type}
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="shareholding_started_at"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Depuis le
              </label>

              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.shareholding_started_at}
                </div>
              ) : (
                <input
                  type="date"
                  id="shareholding_started_at"
                  name="shareholding_started_at"
                  defaultValue={
                    formatDate(formik.values.shareholding_started_at) || null
                  }
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="shareholding_ended_at"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Jusqu'au
              </label>

              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.shareholding_ended_at}
                </div>
              ) : (
                <input
                  type="date"
                  id="shareholding_ended_at"
                  name="shareholding_ended_at"
                  defaultValue={
                    formatDate(formik.values.shareholding_ended_at) || null
                  }
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
        </div>
        <div id="info-company">
          <h2 className="text-lg font-semibold mt-4 mb-2">
            Informations sur la société
          </h2>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="company"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Société
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.company}
                </div>
              ) : (
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="vat"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                TVA
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.vat}
                </div>
              ) : (
                <input
                  type="text"
                  id="vat"
                  name="vat"
                  value={formik.values.vat}
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="is_vat_valid"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                TVA validée ?
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.is_vat_validated.toString()}
                </div>
              ) : (
                <div className="flex h-[42px] items-center">
                  <CheckboxCustom
                    name="is_vat_validated"
                    value={formik.values.is_vat_validated}
                    formik={formik}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div id="info-subscription">
          <h2 className="text-lg font-semibold mt-4 mb-2">
            Informations sur la souscription
          </h2>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="subscription_started_at"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Depuis le
              </label>

              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.subscription_started_at}
                </div>
              ) : (
                <input
                  type="date"
                  id="subscription_started_at"
                  name="subscription_started_at"
                  defaultValue={
                    formatDate(formik.values.subscription_started_at) || null
                  }
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="subscription_ended_at"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Jusqu'au
              </label>

              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.subscription_ended_at}
                </div>
              ) : (
                <input
                  type="date"
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="subscription_ended_at"
                  name="subscription_ended_at"
                  defaultValue={
                    formatDate(formik.values.subscription_ended_at) || null
                  }
                  onChange={formik.handleChange}
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="leaving_reason"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Motif de départ
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.leaving_reason}
                </div>
              ) : (
                <textarea
                  id="leaving_reason"
                  name="leaving_reason"
                  defaultValue={formik.values.leaving_reason}
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="server_users"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Utilisateurs serveur
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.server_users}
                </div>
              ) : (
                <input
                  type="number"
                  id="server_users"
                  defaultValue={formik.values.server_users}
                  onChange={formik.handleChange}
                  step={5}
                  max={10}
                  min={0}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="migrate_from"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Migration
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.migrate_from}
                </div>
              ) : (
                <FormSelect
                  name="migrate_from"
                  items={selectMigrateFromOptions}
                  formik={formik}
                  value={formik.values.migrate_from}
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="payment_status"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Statut du paiement
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.payment_status}
                </div>
              ) : (
                <FormSelect
                  name="payment_status"
                  items={selectPaymentStatusOptions}
                  formik={formik}
                  value={formik.values.payment_status}
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="payment_status"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Service prise de rdv
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.calendar_service}
                </div>
              ) : (
                <FormSelect
                  name="calendar_service"
                  items={selectCalendarOptions}
                  formik={formik}
                  value={formik.values.calendar_service}
                />
              )}
            </div>
          </div>
        </div>
        <div id="info-technical">
          <h2 className="text-lg font-semibold mt-4 mb-2">
            Informations techniques
          </h2>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="operating_system"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Système d'exploitation
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.operating_system}
                </div>
              ) : (
                <FormSelect
                  name="operating_system"
                  items={selectOSOptions}
                  formik={formik}
                  value={formik.values.operating_system}
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="last_pegase_version"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Dernière version de Pégase
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.last_pegase_version}
                </div>
              ) : (
                <input
                  type="text"
                  id="last_pegase_version"
                  name="last_pegase_version"
                  value={formik.values.last_pegase_version}
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="last_pegase_updated_at"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Dernière mise-à-jour le
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.last_pegase_updated_at}
                </div>
              ) : (
                <input
                  type="date"
                  id="last_pegase_updated_at"
                  name="last_pegase_updated_at"
                  defaultValue={
                    formatDate(formik.values.last_pegase_updated_at) || null
                  }
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_base_invitation_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Invitation formation de base
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.formation_base_invitation_date}
                </div>
              ) : (
                <input
                  type="date"
                  id="formation_base_invitation_date"
                  name="formation_base_invitation_date"
                  defaultValue={
                    formatDate(formik.values.formation_base_invitation_date) ||
                    null
                  }
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_base_participation_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                participation formation de base
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.formation_base_participation_date}
                </div>
              ) : (
                <input
                  type="date"
                  id="formation_base_participation_date"
                  name="formation_base_participation_date"
                  defaultValue={
                    formatDate(
                      formik.values.formation_base_participation_date
                    ) || null
                  }
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_base_invitation_last_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Dernière invitation formation de base
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.formation_base_invitation_last_date}
                </div>
              ) : (
                <input
                  type="date"
                  id="formation_base_invitation_last_date"
                  name="formation_base_invitation_last_date"
                  defaultValue={
                    formatDate(
                      formik.values.formation_base_invitation_last_date
                    ) || null
                  }
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_perf_invitation_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Invitation formation de perf
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.formation_perf_invitation_date}
                </div>
              ) : (
                <input
                  type="date"
                  id="formation_perf_invitation_date"
                  name="formation_perf_invitation_date"
                  defaultValue={
                    formatDate(formik.values.formation_perf_invitation_date) ||
                    null
                  }
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_perf_participation_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                participation formation de perf
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.formation_perf_participation_date}
                </div>
              ) : (
                <input
                  type="date"
                  id="formation_perf_participation_date"
                  name="formation_perf_participation_date"
                  defaultValue={
                    formatDate(
                      formik.values.formation_perf_participation_date
                    ) || null
                  }
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
            <div className="mb-4 flex-1">
              <label
                htmlFor="formation_perf_invitation_last_date"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Dernière invitation formation de perf
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.formation_perf_invitation_last_date}
                </div>
              ) : (
                <input
                  type="date"
                  id="formation_perf_invitation_last_date"
                  name="formation_perf_invitation_last_date"
                  defaultValue={
                    formatDate(
                      formik.values.formation_perf_invitation_last_date
                    ) || null
                  }
                  onChange={formik.handleChange}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="mb-4 flex-1">
              <label
                htmlFor="ref_pegase"
                className="block mb-2 text-xs font-regular text-gray-400 "
              >
                Référence Pégase
              </label>
              {isFormLocked ? (
                <div className="h-[42px] flex items-center text-sm">
                  {selectedSubscription.ref_pegase}
                </div>
              ) : (
                <input
                  type="text"
                  id="ref_pegase"
                  name="ref_pegase"
                  onChange={formik.handleChange}
                  value={formik.values.ref_pegase}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              )}
            </div>
          </div>
        </div>
      </ScrollAreaCustom>
      <div className="flex justify-end gap-x-2 px-5 py-4">
        <button
          type="button"
          onClick={(e) => {
            close(e);
          }}
          className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          Fermer
        </button>
        {!isFormLocked && (
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Enregistrer
          </button>
        )}
      </div>
    </form>
  );
};

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classNames("SelectItem", className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="SelectItemIndicator">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default EditSubscriptionForm;

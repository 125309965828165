import React from "react"
import * as ScrollArea from "@radix-ui/react-scroll-area"

const ScrollAreaCustom = ({ children }) => {
  return (
    <ScrollArea.Root className="w-full h-[60vh] overflow-hidden">
      <ScrollArea.Viewport className="w-full h-full px-4">
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar orientation="horizontal">
        <ScrollArea.Thumb />
      </ScrollArea.Scrollbar>
      <ScrollArea.Scrollbar
        orientation="vertical"
        className="flex p-0.5 rounded-full bg-black bg-opacity-30 transition-all w-2"
      >
        <ScrollArea.Thumb className="flex-1 bg-white w-[1px] h-2 before:conten-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2  before:-translate-y-1/2 before:w-full before:h-full before:min-h-[44px] before:min-w-[44px]" />
      </ScrollArea.Scrollbar>
      <ScrollArea.Corner />
    </ScrollArea.Root>
  )
}

export default ScrollAreaCustom

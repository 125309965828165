import * as React from "react";

import { Toaster } from "react-hot-toast";
import { useAuth } from "react-oidc-context";
import LoginPage from "../pages/LoginPage";
import UnauthorizedPage from "../pages/UnauthorizedPage";
import Sidebar from "../sidebar";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Switcher from "../buttons/DarkModeSwitcher";
import {
  MantineProvider,
  createTheme,
  localStorageColorSchemeManager,
  useMantineTheme,
} from "@mantine/core";
import useDarkSide from "../../hooks/useDarkSide";
import ColorSchemeContext from "../../contexts/colorSchemeContext";
import useTheme from "../../hooks/useTheme";

const AdminLayout = ({ children }) => {
  const [colorTheme, setTheme] = useDarkSide();
  const [dTheme, dThemeTogggle] = useTheme();

  const auth = useAuth();
  const qClient = new QueryClient();

  const [colorScheme, setColorScheme] = React.useState(colorTheme);
  if (!auth.isAuthenticated) {
    return <LoginPage />;
  }

  if (auth.user === undefined) {
    return <LoginPage />;
  }

  if (!auth.user.profile.resource_access["web.byvets"].roles.includes("api")) {
    return <UnauthorizedPage />;
  }
  const colorSchemeManager = localStorageColorSchemeManager({
    key: "theme",
  });

  const manTheme = createTheme({
    input: "",
  });
  return (
    <ColorSchemeContext.Provider
      value={{ colorScheme, onChange: setColorScheme }}
    >
      <div className="flex min-h-screen  min-w-full bg-selago-50 dark:bg-slate-800 text-gray-900 dark:text-white">
        <Toaster position="bottom-right" />
        <Sidebar />
        <div className="flex flex-1 flex-col ">
          <div className="p-4 flex justify-between items-center"></div>
          <div className="flex">
            <div className="flex-1 mx-4">
              <div className="flex flex-col justify-between h-full">
                <div className="min-h-[85vh]">{children}</div>
                <div className="pt-8">
                  <ul className="flex justify-center items-center gap-x-4 text-sm">
                    <li className=" text-gray-500 dark:text-gray-400 ">
                      ByVets {new Date().getFullYear()}
                    </li>
                    <li>
                      <a
                        className=" text-gray-500 dark:text-gray-400 hover:text-blue-700"
                        href="https://www.byvets.be/"
                      >
                        Site
                      </a>
                    </li>
                    <li>
                      <a
                        className=" text-gray-500 dark:text-gray-400 hover:text-blue-700"
                        href="https://forum.byvets.be/"
                      >
                        Forum
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ColorSchemeContext.Provider>
  );
};

export default AdminLayout;

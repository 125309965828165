import React from "react";
import FilterSelect from "./FilterSelect";

const FilterValue = ({
  columnType,
  isStringOnly,
  defaultValue,
  onChange,
  setValue,
  valuesCount,
}) => {
  if (isStringOnly) {
    return (
      <input
        type="text"
        defaultValue={defaultValue}
        placeholder="Entrez une valeur"
        onChange={(e) => onChange(e)}
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
      />
    );
  }
  if (columnType === "date") {
    return (
      <input
        type="date"
        defaultValue={defaultValue}
        placeholder="Entrez une valeur"
        onChange={(e) => onChange(e)}
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
      />
    );
  }
  if (valuesCount < 1) {
    return;
  }
  return (
    <input
      type="text"
      defaultValue={defaultValue}
      placeholder="Entrez une valeur"
      onChange={(e) => onChange(e)}
      className={`bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
    />
  );
};

export default FilterValue;

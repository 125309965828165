const filterColumns = [
  {
    id: 1,
    value: "firstname",
    label: "Prénom",
    type: "text",
  },
  {
    id: 2,
    value: "lastname",
    label: "Nom",
    type: "text",
  },
  {
    id: 3,
    value: "omv",
    label: "Numéro ordre",
    type: "text",
  },
  {
    id: 4,
    value: "company",
    label: "Société",
    type: "text",
  },
  {
    id: 5,
    value: "address",
    label: "Rue + n°",
    type: "text",
  },
  {
    id: 6,
    value: "country",
    label: "Pays",
    type: "text",
  },
  {
    id: 7,
    value: "city",
    label: "Ville",
    type: "text",
  },
  {
    id: 8,
    value: "zipcode",
    label: "Code postal",
    type: "text",
  },
  {
    id: 9,
    value: "phone",
    label: "Téléphone",
    type: "text",
  },
  {
    id: 10,
    value: "email",
    label: "Email",
    type: "text",
  },
  {
    id: 11,
    value: "email2",
    label: "Email secondaire",
    type: "text",
  },
  {
    id: 12,
    value: "sexe",
    label: "Sexe",
    type: "text",
  },
  {
    id: 13,
    value: "vat",
    label: "TVA",
    type: "text",
  },
  {
    id: 14,
    value: "deposit",
    label: "Dépôt",
    type: "text",
  },
  {
    id: 15,
    value: "server_users",
    label: "Utilisateurs serveur",
    type: "text",
  },
  {
    id: 16,
    value: "migrate_from",
    label: "Migration de",
    type: "text",
  },
  {
    id: 17,
    value: "price",
    label: "Prix",
    type: "text",
  },
  {
    id: 18,
    value: "is_paid",
    label: "Payé",
    type: "boolean",
  },
  {
    id: 19,
    value: "is_valid",
    label: "Validé",
    type: "boolean",
  },
  {
    id: 20,
    value: "username",
    label: "Nom d'utilisateur",
    type: "text",
  },
  {
    id: 21,
    value: "keycloak_id",
    label: "ID Keycloak",
    type: "text",
  },
  {
    id: 22,
    value: "discourse_id",
    label: "ID Discourse",
    type: "text",
  },
  {
    id: 23,
    value: "license_first_granted_at",
    label: "Première licence",
    type: "date",
  },
  {
    id: 24,
    value: "license_last_granted_at",
    label: "Dernière licence",
    type: "date",
  },
  {
    id: 25,
    value: "last_payment_at",
    label: "Dernier paiement",
    type: "date",
  },

  {
    id: 27,
    value: "created_at",
    label: "Créé le",
    type: "datetime",
  },
  {
    id: 28,
    value: "updated_at",
    label: "Mis à jour le",
    type: "datetime",
  },
  {
    id: 29,
    value: "mollie_id",
    label: "ID Mollie",
    type: "text",
  },
  {
    id: 30,
    value: "payment_status",
    label: "Statut du paiement",
    type: "text",
  },
  {
    id: 31,
    value: "mailing_date",
    label: "Date d'envoi",
    type: "date",
  },
  {
    id: 32,
    value: "is_vat_validated",
    label: "TVA validée",
    type: "boolean",
  },
  {
    id: 33,
    value: "title",
    label: "Titre",
    type: "text",
  },
  {
    id: 34,
    value: "politesse",
    label: "Politesse",
    type: "text",
  },
  {
    id: 35,
    value: "shareholding_type",
    label: "Type d'actionnariat",
    type: "text",
  },
  {
    id: 36,
    value: "shareholding_started_at",
    label: "Début d'actionnariat",
    type: "date",
  },
  {
    id: 37,
    value: "shareholding_ended_at",
    label: "Fin d'actionnariat",
    type: "date",
  },
  {
    id: 38,
    value: "subscription_started_at",
    label: "Début de souscription",
    type: "date",
  },
  {
    id: 39,
    value: "subscription_ended_at",
    label: "Fin de souscription",
    type: "date",
  },
  {
    id: 40,
    value: "leaving_reason",
    label: "Raison de départ",
    type: "text",
  },
  {
    id: 41,
    value: "operating_system",
    label: "Système d'exploitation",
    type: "text",
  },
  {
    id: 42,
    value: "last_pegase_version",
    label: "Dernière version Pégase",
    type: "text",
  },
  {
    id: 43,
    value: "last_pegase_updated_at",
    label: "Dernière mise à jour Pégase",
    type: "date",
  },
  {
    id: 44,
    value: "formation_base_invitation_date",
    label: "Invitation formation base",
    type: "date",
  },
  {
    id: 45,
    value: "formation_base_participation_date",
    label: "Participation formation base",
    type: "date",
  },
  {
    id: 46,
    value: "formation_base_invitation_last_date",
    label: "Dernière invitation formation base",
    type: "date",
  },
  {
    id: 47,
    value: "formation_perf_invitation_date",
    label: "Invitation formation perf",
    type: "date",
  },
  {
    id: 48,
    value: "formation_perf_participation_date",
    label: "Participation formation perf",
    type: "date",
  },
  {
    id: 49,
    value: "formation_perf_invitation_last_date",
    label: "Dernière invitation formation perf",
    type: "date",
  },
  {
    id: 50,
    value: "ref_pegase",
    label: "Réf Pégase",
    type: "text",
  },
  {
    id: 51,
    value: "calendar_service",
    label: "Service calendrier",
    type: "text",
  },
  {
    id: 52,
    value: "formation_compta_invitation_date",
    label: "Invitation formation compta",
    type: "date",
  },
  {
    id: 53,
    value: "formation_compta_participation_date",
    label: "Participation formation compta",
    type: "date",
  },
  {
    id: 54,
    value: "formation_compta_invitation_last_date",
    label: "Dernière invitation formation compta",
    type: "date",
  },
];

const filtersOperators = [
  {
    id: 1,
    value: "$eq",
    label: "égal",
    valuesCount: 1,
  },
  {
    id: 2,
    value: "$eqc",
    label: "égal (case-sensitive)",
    valuesCount: 1,
  },
  {
    id: 3,
    value: "$ne",
    label: "est différent de",
    valuesCount: 1,
  },
  {
    id: 4,
    value: "$contains",
    label: "contient",
    valuesCount: 1,
  },
  {
    id: 5,
    value: "$containsc",
    label: "contient (case-sensitive)",
    valuesCount: 1,
  },
  {
    id: 6,
    value: "$notContains",
    label: "ne contient pas",
    valuesCount: 1,
  },
  {
    id: 7,
    value: "$notContainsc",
    label: "ne contient pas (case-sensitive)",
    valuesCount: 1,
  },
  {
    id: 8,
    value: "$gt",
    label: "plus grand que",
  },
  {
    id: 9,
    value: "$gte",
    label: "plus grand ou égal à",
  },
  {
    id: 10,
    value: "$lt",
    label: "plus petit que",
  },
  {
    id: 11,
    value: "$lte",
    label: "plus petit ou égal à",
  },

  {
    id: 12,
    value: "$null",
    label: "est null",
    valuesCount: 0,
  },
  {
    id: 13,
    value: "$notNull",
    label: "n'est pas null",
    valuesCount: 0,
  },
  {
    id: 14,
    value: "$nullorempty",
    label: "est vide (null ou string vide)",
    valuesCount: 0,
  },
  {
    id: 15,
    value: "$startsWith",
    label: "commence par",
    valuesCount: 1,
  },
  {
    id: 16,
    value: "$startsWithc",
    label: "commence par (case-sensitive)",
    valuesCount: 1,
  },
  {
    id: 17,
    value: "$endsWith",
    label: "finit par",
    valuesCount: 1,
  },
  {
    id: 18,
    value: "$endsWithc",
    label: "finit par (case-sensitive)",
    valuesCount: 1,
  },
];

const getOperatorLabelFromValue = (value) => {
  const operator = filtersOperators.find((item) => item.value === value);
  return operator ? operator.label : "Label not found";
};

const getColumnLabelFromValue = (value) => {
  const operator = filterColumns.find((item) => item.value === value);
  return operator ? operator.label : "Label not found";
};

export {
  filterColumns,
  filtersOperators,
  getColumnLabelFromValue,
  getOperatorLabelFromValue,
};

import { useContext, useState } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import useDarkSide from "../../hooks/useDarkSide";
import ColorSchemeContext from "../../contexts/colorSchemeContext";
import useTheme from "../../hooks/useTheme";
import { MoonIcon, SunIcon } from "@radix-ui/react-icons";

export default function Switcher() {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );
  const colorSchemeContext = useContext(ColorSchemeContext);
  const dark = colorSchemeContext.colorScheme === "dark";
  const toggleDarkMode = (checked) => {
    if (checked) {
      setTheme("light");
    } else {
      setTheme("dark");
    }
    // setTheme(colorTheme);
    setDarkSide(checked);
  };

  const [dTheme, dThemeTogggle] = useTheme();

  return (
    <>
      <button
        type="button"
        className="flex justify-center items-center h-8 w-8 bg-white dark:bg-gray-900 rounded-lg shadow-lg "
        onClick={() => {
          // colorSchemeContext.onChange(dark ? "light" : "dark");
          // setTheme(dark ? "light" : "dark");
          // console.log(colorSchemeContext.colorScheme);
          dThemeTogggle();
        }}
      >
        {dTheme === "dark" ? <SunIcon /> : <MoonIcon />}
      </button>
    </>
  );
}

import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useAuth } from "react-oidc-context";

const TestPage = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const query = useQuery({
    queryKey: ["todos"],
    queryFn: async () => {
      try {
        const resp = await axios.get(
          "https://jsonplaceholder.typicode.com/posts"
        );
        console.log(resp.data);
        return resp.data;
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    },
  });
  return (
    <div>
      <button
        onClick={() => {
          console.log(auth);
        }}
      >
        Add Todo
      </button>
    </div>
  );
};

export default TestPage;
